.filterContainer {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  /* width: 70%; */
}
.filterValues {
  display: flex;
  border: 1px solid #ccc;
  padding: 0.25rem 1rem;
  border-radius: 20px;
}
.filter {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
}
.delete {
  margin-left: 0.5rem;
  cursor: pointer;
  color: #777676;

  font-size: 15px;
  /* font-weight: 700; */
  &:hover {
    color: #3e3d3d;
  }
}
.deleteIcon {
  font-weight: 700;
}

.createListingLink,
.resetAllFiltersButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);

  /* Layout */
  display: inline;
  margin: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
  }
}
